import {StaticImage} from 'gatsby-plugin-image'
import React, {useRef, useEffect, useState, useMemo, useCallback} from 'react'
import * as styles from './ThemesTemplate.css'
import interiorTop from '@assets/images/themes/section5gif.webp'
import interiorTopEN from '@assets/images/themes/section5gifEN.webp'
import interiorTopKR from '@assets/images/themes/section5gifKR.webp'
import interiorTopTW from '@assets/images/themes/section5gifTW.webp'
import FadeFromBottom from '@components/atoms/FadeFromBottom'
import PhoneMockup from '@components/atoms/PhoneMockup'
import Slider from '@components/atoms/Slider'
import MultilineText, {TEXT_STYLE} from '@components/atoms/Text'
import {Lang, LANG, MEDIA_QUERIES} from '@constants/theme'
import useIntersectionObserver from '@hooks/useIntersectionObserver'
import useLocalizedInfo from '@hooks/useLocalizedInfo'

const DELAY = 500

const isBrowser = typeof window !== 'undefined'

const THEME_WIDTH_BY_DEVICE = {
  mobile: {
    width: 210.6125,
    height: 406,
  },
  tablet: {
    width: 363.125,
    height: 700,
  },
  pc: {
    width: 415,
    height: 800,
  },
} as const

export default function ThemesTemplate({lang}: {lang: Lang}) {
  const t = useLocalizedInfo(lang, 'themes')

  const [isMobile, setIsMobile] = useState(
    isBrowser ? window.matchMedia(MEDIA_QUERIES.mobile).matches : false,
  )

  const [isTablet, setIsTablet] = useState(
    isBrowser ? window.matchMedia(MEDIA_QUERIES.tablet1).matches : false,
  )

  const triggerRef = useRef(null)

  const entry = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  })

  const themeSize = useMemo(() => {
    if (isMobile) return THEME_WIDTH_BY_DEVICE.mobile

    if (isTablet) return THEME_WIDTH_BY_DEVICE.tablet

    return THEME_WIDTH_BY_DEVICE.pc
  }, [isMobile, isTablet])

  const infoByLang = useMemo(() => {
    switch (lang) {
      case LANG.ja:
        return {
          interiorBackground: (
            <StaticImage
              src="../../assets/images/themes/interiorImgBottom_ja.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
      case LANG.tw:
        return {
          interiorBackground: (
            <StaticImage
              src="../../assets/images/themes/interiorImgBottom_tw.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
      case LANG.en:
        return {
          interiorBackground: (
            <StaticImage
              src="../../assets/images/themes/interiorImgBottom_en.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
      default:
        return {
          interiorBackground: (
            <StaticImage
              src="../../assets/images/themes/interiorImgBottom_kr.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
    }
  }, [lang])

  const interiorTopImg = useMemo(() => {
    switch (lang) {
      case LANG.ja:
        return interiorTop
      case LANG.tw:
        return interiorTopTW
      case LANG.ko:
        return interiorTopKR
      default:
        return interiorTopEN
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      const mobileMediaQuery = window.matchMedia(MEDIA_QUERIES.mobile)

      const tabletMediaQuery = window.matchMedia(MEDIA_QUERIES.tablet1)

      setIsMobile(mobileMediaQuery.matches)
      setIsTablet(tabletMediaQuery.matches)

      mobileMediaQuery.addEventListener('change', mediaQuery => {
        setIsMobile(mediaQuery.matches)
      })

      tabletMediaQuery.addEventListener('change', mediaQuery =>
        setIsTablet(mediaQuery.matches),
      )
    }
  }, [])

  const renderMainText = useCallback(
    (text: string) => <span className={styles.mainColor}>{text}</span>,
    [],
  )

  return (
    <section className={`themes ${styles.container}`}>
      <div className={styles.background}>
        <div className={styles.background1} />
        <div className={styles.background2} />
      </div>
      <div className={styles.content} ref={triggerRef}>
        <div className={styles.textWrap}>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY}>
            <MultilineText
              text={t.title(renderMainText)}
              textStyle={TEXT_STYLE.title2}
              lang={lang}
              containerClassName={styles.title}
            />
          </FadeFromBottom>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 300}>
            <MultilineText
              textStyle={TEXT_STYLE.body2}
              lang={lang}
              text={t.description}
              containerClassName={styles.description}
            />
          </FadeFromBottom>
        </div>
        <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 600}>
          <div className={styles.slideWrap}>
            <PhoneMockup className={styles.phoneMockup}>
              {infoByLang.interiorBackground}
            </PhoneMockup>
            <div className={styles.slide}>
              <Slider
                itemWidth={themeSize.width}
                itemNum={5}
                gap={32}
                isStarted={entry?.isIntersecting}
                delay={DELAY + 600}>
                <div className={styles.slideTheme}>
                  <StaticImage
                    src="../../assets/images/themes/interiorBox1.png"
                    alt=""
                    layout="fullWidth"
                  />
                </div>
                <div className={styles.slideTheme}>
                  <StaticImage
                    src="../../assets/images/themes/interiorBox2.png"
                    alt=""
                    layout="fullWidth"
                  />
                </div>
                <div className={styles.slideTheme}>
                  <StaticImage
                    src="../../assets/images/themes/interiorBox3.png"
                    alt=""
                    layout="fullWidth"
                  />
                </div>
                <div className={styles.slideTheme}>
                  <StaticImage
                    src="../../assets/images/themes/interiorBox4.png"
                    alt=""
                    layout="fullWidth"
                  />
                </div>
                <div className={styles.slideTheme}>
                  <StaticImage
                    src="../../assets/images/themes/interiorBox5.png"
                    alt=""
                    layout="fullWidth"
                  />
                </div>
              </Slider>
            </div>
            <div className={styles.slideFixed}>
              <img src={interiorTopImg} className={styles.slideFixedImg} />
            </div>
          </div>
        </FadeFromBottom>
      </div>
    </section>
  )
}
