import {Link, navigate} from 'gatsby'
import React, {useState, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import * as styles from './FooterTemplate.css'
import TikTok from '@assets/images/footer/TikTok.png'
import twitterX from '@assets/images/footer/X.png'
import MultilineText, {Text, TEXT_STYLE} from '@components/atoms/Text'
import {LANG, Lang} from '@constants/theme'
import useLocalizedInfo from '@hooks/useLocalizedInfo'

const INFO = {
  privacy: {
    [LANG.ko]: 'https://app.catchsecu.com/document/P/f7865d0b5c1279c',
    [LANG.ja]: '/ja/privacy',
    [LANG.tw]: 'https://app.catchsecu.com/document/P/f7865d0b5c1279c',
    [LANG.en]: 'https://app.catchsecu.com/document/P/f7865d0b5c1279c',
  },
  terms: {
    [LANG.ko]: '/terms',
    [LANG.ja]: '/ja/terms',
    [LANG.tw]: '/terms',
    [LANG.en]: '/terms',
  },
  contact: 'mailto:contactus@monymony.co',
  faq: {
    [LANG.ko]: 'http://sumone.co/faq',
    [LANG.ja]: 'http://sumone.co/faq-ja',
    [LANG.tw]: 'http://sumone.co/faq-tw',
    [LANG.en]: 'http://sumone.co/faq-en',
  },
}

export default function FooterTemplate({lang}: {lang: Lang}) {
  const {i18n} = useTranslation()

  const t = useLocalizedInfo(lang, 'footer')

  const [selected, setSelected] = useState<Lang>(lang)

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newLang = e.target.value as Lang

      setSelected(newLang)

      if (lang !== newLang) {
        i18n.changeLanguage(newLang)
        navigate(`/${newLang}`)
      }
    },
    [i18n, lang],
  )

  const ButtonText = useCallback(
    ({children}: {children: React.ReactNode}) => (
      <Text
        textStyle={TEXT_STYLE.button1}
        lang={lang}
        className={styles.termsText}>
        {children}
      </Text>
    ),
    [lang],
  )

  const Divider = useCallback(
    () => (
      <Text
        textStyle={TEXT_STYLE.button1}
        lang={lang}
        className={styles.termsDivider}>
        |
      </Text>
    ),
    [lang],
  )

  return (
    <footer className={styles.container}>
      <div className={styles.content}>
        {lang === LANG.ja && (
          <div className={styles.snsWebViewWrapper}>
            <div>
              <a
                href="https://twitter.com/sumoneluv"
                target="_blank"
                rel="noreferrer noopener">
                <img src={twitterX} width="45px" height="45px" />
              </a>
            </div>
            <div>
              <a
                href="https://www.tiktok.com/@sumone.jp?is_from_webapp=1&sender_device=pc"
                target="_blank"
                rel="noreferrer noopener">
                <img src={TikTok} width="45px" height="45px" />
              </a>
            </div>
          </div>
        )}
        <div className={styles.termsWrap}>
          <ButtonText>
            <Link to={INFO.privacy[lang]}>{t.privacy}</Link>
          </ButtonText>
          <Divider />
          <ButtonText>
            <Link to={INFO.terms[lang]}>{t.terms}</Link>
          </ButtonText>
          <Divider />
          <ButtonText>
            <a href={INFO.contact}>{t.contact}</a>
          </ButtonText>
          <Divider />
          <ButtonText>
            <a href={INFO.faq[lang]}>{t.faq}</a>
          </ButtonText>
          <Divider />
          <ButtonText>
            <select
              onChange={onChange}
              name="language"
              className={styles.langSelect}>
              <option selected={selected === LANG.ko} value={LANG.ko}>
                한국어
              </option>
              <option selected={selected === LANG.ja} value={LANG.ja}>
                日本語
              </option>
              <option selected={selected === LANG.tw} value={LANG.tw}>
                繁體中文（台灣）
              </option>
              <option selected={selected === LANG.en} value={LANG.en}>
                English
              </option>
            </select>
          </ButtonText>
        </div>
        <MultilineText
          text={[
            [
              'CEO : Minha Hwang | ',
              'Address : 04000, 35, World Cup buk-ro Mapo-gu, Seoul | ',
              '804-88-02029',
            ],
          ]}
          textStyle={TEXT_STYLE.button1}
          lang={lang}
          containerClassName={styles.infoText}
        />
        <Text
          textStyle={TEXT_STYLE.button1}
          lang={lang}
          className={styles.copyrightText}>
          Copyright © Monymony Corp. All rights reserved.
        </Text>
        {lang === LANG.ja && (
          <div className={styles.snsMobileViewWrapper}>
            <div>
              <a
                href="https://twitter.com/sumoneluv"
                target="_blank"
                rel="noreferrer noopener">
                <img src={twitterX} width="45px" height="45px" />
              </a>
            </div>
            <div>
              <a
                href="https://www.tiktok.com/@sumone.jp?is_from_webapp=1&sender_device=pc"
                target="_blank"
                rel="noreferrer noopener">
                <img src={TikTok} width="45px" height="45px" />
              </a>
            </div>
          </div>
        )}
      </div>
    </footer>
  )
}
