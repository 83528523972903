import type {RenderText} from './types'

export const KO = {
  title: {
    description: '연인과 함께 쓰는 커플 다이어리',
  },
  quote: {
    quoteText: [
      ['이 사람과 오래오래 ', '함께하고 싶다고'],
      ['생각한 순간은 언제인가요?'],
    ],
    description: [
      [
        '차곡차곡 쌓이는 진심 어린 답변들은,',
        ' 몇 번이고 다시 읽을 수 있는 ',
        '소중한 추억이 될 거예요.',
      ],
    ],
  },
  introduction: {
    title: (renderMainText: RenderText) => [
      ['하루 한 번 배달되는'],
      [renderMainText('오늘의 질문')],
    ],
    description: [
      [
        '매일 같은 시간 날아오는 질문 속, ',
        '말로 꺼내기엔 어색했던 진심들을 써 내려가다 보면 ',
        '서로에 대해 몰랐던 점도 알 수 있을 거예요.',
      ],
    ],
  },
  characters: {
    title: (renderMainText: RenderText) => [
      ['연인과 함께 키우는 캐릭터'],
      [renderMainText('반려몽')],
    ],
    description: [
      ['홈 화면의 작은 알에서 태어난 이 친구들은 '],
      ['두 분의 답변에 담긴 진심을 먹으며 점차 성장합니다.'],
    ],
  },
  themes: {
    title: (renderMainText: RenderText) => [
      ['취향에 맞게 꾸미는 ', renderMainText('인테리어')],
    ],
    description: [
      [
        '연인과 함께 모은 조약돌로 반려몽의 아늑한 방을 ',
        '자유롭게 꾸며보세요.',
      ],
    ],
  },
  reviewAndStore: {
    title: [['사랑하는 그 사람과 ', '지금 바로 시작해 보세요!']],
    description: [['전세계 1000만 연인들이 함께 사용하고 있어요.']],
    review1: {
      title: [['서로를 알아갈 수 있는 ', '시간이에요!']],
      content:
        '저는 만난 지 4년 가까이 되어가는 젊은 커플입니다! 오래 만난 만큼 가끔 새로운 것을 원할 때가 있었죠. 그런데 우연히 썸원을 알게 되어 지금 약 3개월 동안 사용 중인데요! 오래 만났지만 사소한 부분에서 몰랐던 점을 알게 되고, 하루하루 정해진 시간에 잠깐이더라도 함께 하는 일이 생기니 너무너무 좋습니다.',
    },
    review2: {
      title: [['커플들이 꼭 해봤으면 ', '하는 앱!']],
      content:
        '리뷰는 처음 써요! 저는 남자친구랑 이런저런 얘기 하는 거 좋아하는데 썸원 앱을 발견해서 더 진솔한 얘기를 하게 돼서 좋아요! 생각지도 못한 질문에 답하며, 더 깊게 생각하게 되기도 하고 상대방 답을 보고 웃기도 하고.. 사이가 더 돈독해지는 거 같아서 다른 친구들에게도 추천했어요. 오늘로 92번째 질문 답했네요!!  질문의 퀄리티도 너무 좋구요!! 앞으로도 계속 썸원과 함께 하고 싶어요😆',
    },
    review3: {
      title: '너무 사랑스러운 앱',
      content:
        '깔까 말까 고민하지 말고 당장 까세요! 남자친구가 별로 안 하고 싶어 하면 어쩌지 했는데 지금은 저보다 남자친구가  더 신나서 해요. 그만큼 썸원이 너무너무 좋아요ㅠㅠ 제가 남자친구를 다 알고 있다고 생각해도 평소에 생각해 보지 못할만한 질문들을 썸원이 대신 물어봐 줘서 더 알아가는 느낌이에요!! 이런 귀염 뽀짝한 어플을 만들어주셔서 감사해요❤️ 매일 썸원 받는 질문이 기다려집니다! 감질나게 하루에 하나에만 답변 하는 것도 질리지 않게 오래 할 수 있는 점 중 하나인 것 같아요. 좋은 앱 만들어주셔서 감사합니다 개발자님!',
    },
    review4: {
      title: '이런 앱 너무 좋아요!!!',
      content:
        '무심코 상대에 놓쳤던 부분에 대해 서로가 조금씩 알아갈 수 있는 앱인 것 같아요! 한 번 더 상대를 생각하게 되고, 나중에 다시 보면  정말 좋은 추억이 될 것 같아서 만족합니다 ㅎㅎㅎ',
    },
  },
  footer: {
    privacy: '개인정보처리방침',
    terms: '이용약관',
    contact: '제휴문의',
    faq: '자주묻는질문',
  },
}
