import type {RenderText} from './types'

export const JA = {
  title: {
    description: '恋人たちの恋の記録アプリ',
  },
  quote: {
    quoteText: [
      ['この人と末長く', '幸せになりたいと思った'],
      ['瞬間はいつですか？'],
    ],
    description: [
      [
        '真心こもった質問の回答は、何度も',
        '読み返す事が出来る宝物になります。',
      ],
    ],
  },
  introduction: {
    title: (renderMainText: RenderText) => [
      ['一日に一つの', renderMainText('質問'), 'が'],
      ['二人の愛を育てます'],
    ],
    description: [
      [
        'たまもんがお二人に届ける質問',
        '毎日同じ時間に届く質問の中に',
        '直接言えなかった思いを綴ったら',
        'お互いについて知らなかったことが',
        'もっと知れるはずです。',
      ],
    ],
  },
  characters: {
    title: (renderMainText: RenderText) => [
      ['恋人と育てるペット'],
      [renderMainText('たまもん')],
    ],
    description: [
      [
        '恋人と一緒に育てるペット、たまもん',
        'メイン画面の小さなたまごから生まれたたまもんは',
        'お二人の質問の中の愛を食べてだんだん成長します。',
      ],
    ],
  },
  themes: {
    title: (renderMainText: RenderText) => [
      ['好きなように飾る', renderMainText('インテリア')],
    ],
    description: [
      [
        '恋人と一緒に集めた石ころで、',
        'たまもんのお家を自由に飾ってみましょう。',
      ],
    ],
  },
  reviewAndStore: {
    title: [['今すぐ愛するあの人と', '始めてみませんか？']],
    description: [['全世界1000万人の恋人たちが愛用中です！']],
    review1: {
      title: 'とってもいいアプリ！',
      content:
        '彼氏とはじめました！広告を見て、少しずつですが石ころをゲットして、お部屋を可愛くしています。最初はそんなに乗り気じゃなかった彼氏も今では自分の方から答えて、つんつんしてきます。仲が深まる素敵なアプリです！質問も簡単で、気軽に答えられる質問なので答えやすくて良いです！これからも楽しませてもらいたいと思います。',
    },
    review2: {
      title: '入れて良かった',
      content:
        'LINEは必要最低限、電話もあまりしないタイプの私ですが、サムワンで毎日少しずつ相手のことを知ることで、会わない時間も相手ののことを考える時間が増えました。空いた時間に回答できる手軽さや、相手の愛情を感じられる質問が気に入って、３日坊主にならずに利用しています。たまもんというキャラクターが少しずつ成長していくのも、面白いですし、課金しなくても充分楽しめます。',
    },
    review3: {
      title: '本当にカップルにオススメ◎',
      content:
        'とてもシンプルで使いやすいアプリです。質問が1日1個届く、答える、互いの回答を見る。それの繰り返しです。 この質問、何気ない質問から、結構深く考える質問まで、色々とあります。特に、価値観の話だとか、何が好きだとか、改めて話し合ったりするのは恥ずかしいなぁ、と思う方や、もっと相手のことを知りたい、と思う方達にオススメです。 恋人と使っていて、意外なこと、知らなかったことを知れたりするのがこのアプリの醍醐味だと思います。',
    },
    review4: {
      title: 'いつもお世話になってます！',
      content:
        '彼氏さんと2人でいつも利用させて頂いてます。 なかなか聞けないようなことでもお互い話せるいい機会になってます。 公式Twitterの方では、アカウント変更した際の対応もして頂きました。本当にありがとうございました。 これからも利用させていただきます！',
    },
  },
  footer: {
    privacy: 'プライバシーポリシー',
    terms: '利用規約',
    contact: '業務提携に関するお問合せ',
    faq: 'よくある質問',
  },
}
