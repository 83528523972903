/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import {useStaticQuery, graphql} from 'gatsby'
import * as React from 'react'
import {Helmet} from 'react-helmet'

function Seo({
  description = '',
  lang = 'ko',
  meta = [],
  title,
}: {
  description?: string
  lang?: string
  meta?: Array<{name: string; content: string}>
  title: string
}) {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: 'https://www.sumone.co/opengraph.png',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: 'https://www.sumone.co/opengraph.png',
        },
        {
          property: 'al:ios:url',
          content: 'com.sandfox.sumone://',
        },
        {
          property: 'al:ios:app_store_id',
          content: '1469506430',
        },
        {
          property: 'al:ios:app_name',
          content: 'SumOne',
        },
        {
          property: 'al:android:app_name',
          content: 'SumOne',
        },
        {
          property: 'al:android:package',
          content: 'com.sumne',
        },
        {
          property: 'al:android:url',
          content: 'https://links.sumone.co/',
        },
      ].concat(meta)}
    />
  )
}

export default Seo
