import type {RenderText} from './types'

export const TW = {
  title: {
    description: '與親愛的對方記錄下愛的回憶',
  },
  quote: {
    quoteText: [['請問有哪一個瞬間會'], ['讓您想跟對方走一輩子呢?']],
    description: [
      ['一點一滴累積下來的真心，'],
      ['會成為沒辦法與', '任何東西相比的獨特回憶。'],
    ],
  },
  introduction: {
    title: (renderMainText: RenderText) => [
      ['一天一問'],
      ['收到關於', renderMainText('愛的問題')],
    ],
    description: [
      ['日復一日，可了解對方更深。'],
      ['每天在「想問」留下說不出的真心，'],
      ['兩位的關係肯定會變得更加密切。'],
    ],
  },
  characters: {
    title: (renderMainText: RenderText) => [
      ['與另一半照顧'],
      ['可愛的', renderMainText('小寶夢')],
    ],
    description: [
      ['從小小的蛋中孵化的', '可愛寶貝小寶夢！'],
      ['它觀察兩位紀錄', '愛的回答時慢慢成長。'],
    ],
  },
  themes: {
    title: (renderMainText: RenderText) => [
      ['按照您的喜好佈置', renderMainText('小寶夢之家')],
    ],
    description: [['使用與對方一起儲蓄的鵝卵石', '自由佈置舒適的家']],
  },
  reviewAndStore: {
    title: [['立即與對方開始', '探討「愛」之旅吧！']],
    description: [['下載共計1000萬，/全世界的情侶在使用']],
    review1: {
      title: '在SumOne可以認識跟平時不同的男友',
      content:
        '透過每天都回答一個問題，了解對方心裡在想什麼，也會更有話題，好像回到剛開始認識或交往的時候～每天都很期待問題有別於其他情侶的app，這個可玩性更多，推推～',
    },
    review2: {
      title: '熱戀期必備APP！',
      content: '和熱戀的女友每日的問答，增添了意外的話題與樂趣。',
    },
    review3: {
      title: '功能佳、風格可愛、問答非常吸引',
      content:
        '逛商店剛好看到這款app，風格真的超可愛XD功能也都還不錯，問答的部分蠻有趣的，居然還能佈置房間😂😂',
    },

    review4: {
      title: '可輕鬆使用，期待每一天！',
      content: '很可愛又有趣的程式🥰每天都很期待問題是什麼☺️',
    },
  },
  footer: {
    privacy: '個人資訊處理方針',
    terms: '使用條款',
    contact: '諮詢合作',
    faq: '經常問的問題（FAQ）',
  },
}
