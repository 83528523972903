import {StaticImage} from 'gatsby-plugin-image'
import React, {useMemo, useState, useEffect} from 'react'
import sumoneLogo from '@assets/images/title/SumOnelogo_motionwebp2.webp'
import appStore from '@assets/images/title/appStore@3x.png'
import googlePlay from '@assets/images/title/googlePlay@3x.png'
import Float from '@components/atoms/Float'
import MultilineText from '@components/atoms/Text'
import {Lang} from '@constants/theme'
import useLocalizedInfo from '@hooks/useLocalizedInfo'
import './TitleTemplate.css'

interface ImageButtonProps {
  img: string
  onClick?: () => void
}

export function ImageButton({img, onClick}: ImageButtonProps) {
  return (
    <div className="title-template-image-button" onClick={onClick}>
      <img src={img} className="title-template-image-button-img" />
    </div>
  )
}

const isBrowser = typeof window !== 'undefined'

const initIsMobile = isBrowser
  ? window.matchMedia('screen and (max-width: 959px').matches
  : false

export default function TitleTemplate({lang}: {lang: Lang}) {
  const t = useLocalizedInfo(lang, 'title')
  const gtag = isBrowser ? window.gtag : () => {}
  const [isMobile, setIsMobile] = useState(initIsMobile)

  const fontFamily = useMemo(() => {
    switch (lang) {
      case 'ko':
        return 'GyeonggiBatang'
      case 'ja':
        return 'mamelon'
      case 'tw':
        return 'openhuninn'
      case 'en':
        return 'GyeonggiBatang'
      default:
        return undefined
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      const mobileMediaQuery = window.matchMedia(
        'screen and (max-width: 959px)',
      )

      setIsMobile(mobileMediaQuery.matches)

      mobileMediaQuery.addEventListener('change', mediaQuery => {
        setIsMobile(mediaQuery.matches)
      })
    }
  }, [])

  return (
    <header style={{fontFamily: fontFamily}}>
      <div className="title-area">
        <div className="title-content">
          <img className="title-sumone-logo" src={sumoneLogo} />
          <MultilineText
            text={t.description}
            containerClassName="title-subtitle"
            textAlign="center"
          />
          <div className="title-button-area">
            <a
              href="https://play.google.com/store/apps/details?id=com.sumone"
              target="_blank"
              rel="noreferrer noopener">
              <ImageButton
                img={googlePlay}
                onClick={
                  isBrowser
                    ? () => {
                        gtag('event', 'install_button_click', {
                          section: 'title',
                          store: 'google',
                        })
                      }
                    : undefined
                }
              />
            </a>
            <a
              href="https://apps.apple.com/app/sumone/id1469506430"
              target="_blank"
              rel="noreferrer noopener">
              <ImageButton
                img={appStore}
                onClick={
                  isBrowser
                    ? () => {
                        gtag('event', 'install_button_click', {
                          section: 'title',
                          store: 'apple',
                        })
                      }
                    : undefined
                }
              />
            </a>
          </div>
        </div>
        <div className="title-illust">
          {isMobile ? (
            <StaticImage
              src="../../assets/images/title/eggmon.png"
              className="title-eggmon"
              alt=""
              placeholder="none"
            />
          ) : (
            <StaticImage
              src="../../assets/images/title/eggmon@3x.png"
              className="title-eggmon"
              alt=""
              placeholder="none"
            />
          )}
        </div>
        <Float className="title-haku1">
          <StaticImage
            src="../../assets/images/title/haku1@3x.png"
            className="title-haku1-img"
            alt=""
            placeholder="none"
          />
        </Float>
        <Float className="title-haku2" delay={1500} floatY={16} duration={2500}>
          <StaticImage
            src="../../assets/images/title/haku2@3x.png"
            className="title-haku2-img"
            alt=""
            placeholder="none"
          />
        </Float>
        <Float className="title-haku3" delay={700}>
          <StaticImage
            src="../../assets/images/title/haku3@3x.png"
            className="title-haku3-img"
            alt=""
            placeholder="none"
          />
        </Float>
        <Float className="title-letter1" delay={200}>
          <StaticImage
            src="../../assets/images/title/letter1@3x.png"
            className="title-letter1-img"
            alt=""
            placeholder="none"
          />
        </Float>
        <Float className="title-letter2" delay={900}>
          <StaticImage
            src="../../assets/images/title/letter2@3x.png"
            className="title-letter2-img"
            alt=""
            placeholder="none"
          />
        </Float>
        <Float className="title-letter3" delay={2000}>
          <StaticImage
            src="../../assets/images/title/letter3.png"
            className="title-letter3-img"
            alt=""
            placeholder="none"
          />
        </Float>
        <Float className="title-letter4" delay={100}>
          <StaticImage
            src="../../assets/images/title/letter4.png"
            className="title-letter4-img"
            alt=""
            placeholder="none"
          />
        </Float>
      </div>
    </header>
  )
}
