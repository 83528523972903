import {navigate} from 'gatsby'
import React, {useEffect, useMemo} from 'react'
import Layout from '@components/Layout'
import Seo from '@components/Seo'
import CharactersTemplate from '@components/templates/CharactersTemplate'
import FooterTemplate from '@components/templates/FooterTemplate'
import IntroductionTemplate from '@components/templates/IntroductionTemplate'
import QuoteTemplate from '@components/templates/QuoteTemplate'
import ReviewAndStoreTemplate from '@components/templates/ReviewAndStoreTemplate'
import ThemesTemplate from '@components/templates/ThemesTemplate'
import TitleTemplate from '@components/templates/TitleTemplate'
import '@fontsource/noto-sans-kr'
import '../css/index.css'
import {LANG, Lang} from '@constants/theme'
import useLanguage from '@hooks/useLanguage'

function Content({langFromPath}: {langFromPath?: Lang}) {
  const detectedLang = useLanguage()

  useEffect(() => {
    if (!langFromPath) {
      navigate(`/${detectedLang}`)
    }
  }, [langFromPath, detectedLang])

  const lang = useMemo(
    () => langFromPath ?? detectedLang,
    [langFromPath, detectedLang],
  )

  return (
    <>
      <TitleTemplate lang={lang} />
      <QuoteTemplate lang={lang} />
      <IntroductionTemplate lang={lang} />
      <CharactersTemplate lang={lang} />
      <ThemesTemplate lang={lang} />
      <ReviewAndStoreTemplate lang={lang} />
      <FooterTemplate lang={lang} />
    </>
  )
}

export function IndexTemplate({lang}: {lang?: Lang}) {
  return (
    <Layout>
      <Content langFromPath={lang} />
    </Layout>
  )
}

export default function IndexPage() {
  return (
    <>
      <Seo
        title={'SumOne ㅡ 1000만 번 사랑에 빠진 앱'}
        description={'연인과 함께 쓰는 커플 다이어리'}
        lang={LANG.ko}
      />
      <IndexTemplate />
    </>
  )
}
