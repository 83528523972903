import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import React from 'react'
import {initReactI18next, I18nextProvider} from 'react-i18next'
import {LANG} from '@constants/theme'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: LANG.ko,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        // 'htmlTag',
        'navigator',
        'path',
      ],
    },
  })

interface Props {
  children?: React.ReactNode
}

export default function Layout({children}: Props) {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
