import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {LANG, Lang} from '@constants/theme'

export default function useLanguage() {
  const {i18n} = useTranslation()

  const lang = useMemo<Lang>(() => {
    const splittedLang = i18n.language.split('-')

    let langCode = splittedLang[0].toLowerCase()

    if (langCode === 'zh') {
      langCode = splittedLang[1].toLowerCase()
    }

    return Object.values(LANG).find(element => element === langCode) ?? LANG.ko
  }, [i18n.language])

  return lang
}
