import {useSpring, animated} from '@react-spring/web'
import React from 'react'
import * as styles from './Slider.css'

interface Props {
  children: React.ReactChild[]
  itemWidth: number
  itemNum: number
  gap?: number
  isStarted?: boolean
  speed?: number
  delay?: number
}

export default function Slider({
  children,
  itemWidth,
  itemNum,
  gap = 0,
  isStarted = false,
  speed = 1500,
  delay = 0,
}: Props) {
  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  const sliderStyle = useSpring({
    config: {frequency: 1},
    loop: true,
    delay,
    from: {x: (itemWidth + gap) * ((itemNum - 1) / 2)},
    to: isStarted
      ? async (next, cancel) => {
          for (let i = 1; i <= itemNum - 1; i++) {
            await wait(speed)
            await next({x: (itemWidth + gap) * ((itemNum - 1) / 2 - i)})
          }

          for (let i = itemNum - 2; i >= 0; i--) {
            await wait(speed)
            await next({x: (itemWidth + gap) * ((itemNum - 1) / 2 - i)})
          }
        }
      : undefined,
  })

  return (
    <animated.div
      className={styles.slider}
      style={{...sliderStyle, gap: `${gap}px`}}>
      {children}
    </animated.div>
  )
}
