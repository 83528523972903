import {StaticImage} from 'gatsby-plugin-image'
import React, {useRef, useEffect, useState, useMemo, useCallback} from 'react'
import * as styles from './IntroductionTemplate.css'
import Video1 from '@assets/videos/web_video_1_4.mp4'
import Video2 from '@assets/videos/web_video_2_4.mp4'
import FadeFromBottom from '@components/atoms/FadeFromBottom'
import PhoneMockup from '@components/atoms/PhoneMockup'
import MultilineText, {TEXT_STYLE} from '@components/atoms/Text'
import {LANG, Lang, MEDIA_QUERIES} from '@constants/theme'
import useIntersectionObserver from '@hooks/useIntersectionObserver'
import useLocalizedInfo from '@hooks/useLocalizedInfo'

const DELAY = 500

const isBrowser = typeof window !== 'undefined'

export default function IntroductionTemplate({lang}: {lang: Lang}) {
  const t = useLocalizedInfo(lang, 'introduction')

  const [isMobile, setIsMobile] = useState(
    isBrowser ? window.matchMedia(MEDIA_QUERIES.mobile).matches : false,
  )

  const [isTablet1, setIsTablet1] = useState(
    isBrowser ? window.matchMedia(MEDIA_QUERIES.tablet1).matches : false,
  )

  const [isTablet2, setIsTablet2] = useState(
    isBrowser ? window.matchMedia(MEDIA_QUERIES.tablet2).matches : false,
  )

  const triggerRef = useRef(null)

  const entry = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  })

  const renderMainText = useCallback(
    (text: string) => <span className={styles.mainColor}>{text}</span>,
    [],
  )

  const infoByLang = useMemo(() => {
    switch (lang) {
      case LANG.ja:
        return {
          phone1: (
            <StaticImage
              src="../../assets/images/introduction/introduction_1_ja.png"
              layout="fullWidth"
              alt=""
            />
          ),
          phone2: (
            <StaticImage
              src="../../assets/images/introduction/introduction_2_ja.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
      case LANG.tw:
        return {
          phone1: (
            <StaticImage
              src="../../assets/images/introduction/introduction_1_tw.png"
              layout="fullWidth"
              alt=""
            />
          ),
          phone2: (
            <StaticImage
              src="../../assets/images/introduction/introduction_2_tw.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
      case LANG.ko:
        return {
          phone1: (
            <StaticImage
              src="../../assets/images/introduction/introduction_1_kr.png"
              layout="fullWidth"
              alt=""
            />
          ),
          phone2: (
            <StaticImage
              src="../../assets/images/introduction/introduction_2_kr.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
      default:
        return {
          phone1: (
            <StaticImage
              src="../../assets/images/introduction/introduction_1_en.png"
              layout="fullWidth"
              alt=""
            />
          ),
          phone2: (
            <StaticImage
              src="../../assets/images/introduction/introduction_2_en.png"
              layout="fullWidth"
              alt=""
            />
          ),
        }
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      const mobileMediaQuery = window.matchMedia(MEDIA_QUERIES.mobile)

      const tablet1MediaQuery = window.matchMedia(MEDIA_QUERIES.tablet1)

      const tablet2MediaQuery = window.matchMedia(MEDIA_QUERIES.tablet2)

      setIsMobile(mobileMediaQuery.matches)
      setIsTablet1(tablet1MediaQuery.matches)
      setIsTablet2(tablet2MediaQuery.matches)

      mobileMediaQuery.addEventListener('change', mediaQuery => {
        setIsMobile(mediaQuery.matches)
      })

      tablet1MediaQuery.addEventListener('change', mediaQuery => {
        setIsTablet1(mediaQuery.matches)
      })

      tablet2MediaQuery.addEventListener('change', mediaQuery => {
        setIsTablet2(mediaQuery.matches)
      })
    }
  }, [])

  return (
    <section className={`introduction ${styles.container}`}>
      <div className={styles.content} ref={triggerRef}>
        <div className={`${styles.vertical} ${styles.verticalLeft}`}>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY}>
            <MultilineText
              text={t.title(renderMainText)}
              textStyle={TEXT_STYLE.title2}
              lang={lang}
              containerClassName={styles.title}
            />
          </FadeFromBottom>
          <div className={styles.pc}>
            <FadeFromBottom
              isVisible={entry?.isIntersecting}
              delay={DELAY + 300}>
              <PhoneMockup className={styles.phoneMockup}>
                {infoByLang.phone1}
              </PhoneMockup>
            </FadeFromBottom>
          </div>
        </div>
        <div className={styles.vertical}>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 600}>
            <PhoneMockup className={styles.phoneMockup}>
              {infoByLang.phone2}
            </PhoneMockup>
          </FadeFromBottom>
          <FadeFromBottom
            isVisible={entry?.isIntersecting}
            delay={
              isMobile || isTablet1 || isTablet2 ? DELAY + 300 : DELAY + 900
            }
            containerClassName={styles.descriptionContainer}>
            <MultilineText
              text={t.description}
              textStyle={TEXT_STYLE.body2}
              lang={lang}
              containerClassName={styles.description}
            />
          </FadeFromBottom>
        </div>
      </div>
    </section>
  )
}
