import "sumone-landing/src/components/atoms/Text.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"source\":\"#H4sIAAAAAAAAA6WWzY6bMBSF93kKlu2CCBsIJLOp2kWlVqqqtvvKAwacgmGANMlUefcSJvPjcJzYymZGss53fc+1T8z8N0mb/TrJPOffzHFS0TUl26+crOS7u2Hh+N9NRcuTXtRy5SR1uank3ewwmz+TZCRZKXLpip5X3RPtdj1re0VJp8qEy563isrX1OMyVXSBWcdtvX1Z3rasWTnHv0qlcKy03nS9yPZuUg8tyV7rYoHVwEl0oe65m3jUbovBsds1LOErp2m5IlmOkmwo4265yIuhVOh5ioS9SjJWiXKYy+c9r2Wei4+sZzJX1PdTdcUqXtbqASdTWd1wWWykkKoyvd4hnxb7Vve185PJzvn6Q9FmZv3lxv0VFpsLs83Xxpv/mQ4nOBtOadFfZdafNO6vtti8Mdv8wXjzVnNzTmudeBwCQfxmjHgpJHeLk4zE85ge199U6yys9Je0X74r2s0l7a9Pivbv1FE0cUQpcOSTebw4c7S1cLSzcLS3cPRodEYBcEQXc2955oh4FpYIsfBE6HVTHyqeCuZ0Scu5dJhMnXeVkO5WpH0xHFTgNbv3Y5Wzn1bFa7B48nrmNjotH97i97fhyW04B3gE8SCeh6BAdtv+OcBjc7wAeAjxRQTbF8YFohjga+PxQbyc4hQPz18CvJrivocPD3mXAKfmeA2aJ7h5H+CNMR5QgD8A3L+CX0t3EI7pflpmu5fQH4d/PfQ+PrnQMwu9Dg/MQm+Dg9D7OHQhGj2IvA2OIo9vLcRB5HXeEQ4C7wcYJ2aB1+2OcBR47N1H1wYE3gYHgac48BAHgSdLjKPJg8ATfG1oZBh47P0Vx4F/TXYQGCWb4gviozZBsm1wkGwbHCRbh6PnFCTbBgfJ1j1nho+55icdPigg2ZoHheJPGZBtzf6aAiDdBPsfvntRAZBvgr9GYMJAvgn+GoE4yjc+fUph+yjhVgVAxnUDfFPg8B8bok5SrBIAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var TEXT_STYLE = {headline:'headline',title1:'title1',title2:'title2',body1:'body1',body2:'body2',button1:'button1',reviewTitle:'reviewTitle',reviewBody:'reviewBody'};
export var body1 = {ko:'_1dpyjcfl _1dpyjcfk',ja:'_1dpyjcfm _1dpyjcfk',tw:'_1dpyjcfn _1dpyjcfk'};
export var body2 = {ko:'_1dpyjcfo _1dpyjcfk',ja:'_1dpyjcfp _1dpyjcfk',tw:'_1dpyjcfq _1dpyjcfk'};
export var button1 = {ko:'_1dpyjcfs _1dpyjcfr',ja:'_1dpyjcft _1dpyjcfr',tw:'_1dpyjcfu _1dpyjcfr'};
export var container = {left:'_1dpyjcf1 _1dpyjcf0',center:'_1dpyjcf2 _1dpyjcf0',right:'_1dpyjcf3 _1dpyjcf0'};
export var headline = {ko:'_1dpyjcfa _1dpyjcf9',ja:'_1dpyjcfb _1dpyjcf9',tw:'_1dpyjcfc _1dpyjcf9'};
export var line = {left:'_1dpyjcf5 _1dpyjcf4',center:'_1dpyjcf6 _1dpyjcf4',right:'_1dpyjcf7 _1dpyjcf4'};
export var reviewBody = {ko:'_1dpyjcf10 _1dpyjcfz',ja:'_1dpyjcf11 _1dpyjcfz',tw:'_1dpyjcf12 _1dpyjcfz'};
export var reviewTitle = {ko:'_1dpyjcfw _1dpyjcfv',ja:'_1dpyjcfx _1dpyjcfv',tw:'_1dpyjcfy _1dpyjcfv'};
export var str = '_1dpyjcf8';
export var title1 = {ko:'_1dpyjcfe _1dpyjcfd',ja:'_1dpyjcff _1dpyjcfd',tw:'_1dpyjcfg _1dpyjcfd'};
export var title2 = {ko:'_1dpyjcfh _1dpyjcfd',ja:'_1dpyjcfi _1dpyjcfd',tw:'_1dpyjcfj _1dpyjcfd'};