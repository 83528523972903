import "sumone-landing/src/components/templates/FooterTemplate.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"source\":\"#H4sIAAAAAAAAA51TXW/CIBR991eQLEu2hxqq1Wp92T9ZsL3Wa1poAD8X//sopaa0Os1C0gY4nHsOnDv+DvcKd5cDJT8jQo6Y6W1CQkrfV2aaoaoKdk7IpoBTvbDbK42bc5AKroHrhKTmC7LeYgXmPEANpeouV0KhRsETIqFgGg9gV1mWIc8TEtHqRKazyrBfR+NWTGjFDKrX/yBDCWnDmIpiX/L/6OrUmrxWS4pjvZyzylyP1fucemqpjUohE/I2j+vhASIPALQeHmBmASWTOfJAC1N7Qpva7SFG62EVG++BwgsYgZPehc4HPOFLPLTHE3spabVsAfOtufSZmz+PjapYCsEa9BGA/5Eep3gttBZlkxZPz8J/PC44ePtLu78WMgNjkq7uPIczfLx5sC/wVUKGjKhUGoGE8Yx8lOwUOOdRtKxOn5Z77DdQJ9rTyEWbxOEt5IRcu4dCd8izb6OnNJN6gJ84/ONO6HEh34JE3ez80SVelchV6d1rDzV3KC9Vizsm45foFg7VD9cwXncC9rBfX8new/T53qa09Xb9BTnQayw2BQAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var container = '_1usijzv0';
export var content = '_1usijzv1';
export var copyrightText = '_1usijzv6';
export var infoText = '_1usijzv5';
export var langSelect = '_1usijzv9';
export var snsMobileViewWrapper = '_1usijzv8';
export var snsWebViewWrapper = '_1usijzv7';
export var termsDivider = '_1usijzv4';
export var termsText = '_1usijzv3';
export var termsWrap = '_1usijzv2';