import React, {useRef} from 'react'
import * as styles from './ReviewAndStoreTemplate.css'
import ReviewStar from '@assets/images/review-and-store/reviewStar.svg'
import appStore from '@assets/images/title/appStore@3x.png'
import googlePlay from '@assets/images/title/googlePlay@3x.png'
import FadeFromBottom from '@components/atoms/FadeFromBottom'
import Float from '@components/atoms/Float'
import MultilineText, {TEXT_STYLE, Text} from '@components/atoms/Text'
import {Lang} from '@constants/theme'
import useIntersectionObserver from '@hooks/useIntersectionObserver'
import useLocalizedInfo from '@hooks/useLocalizedInfo'

interface ImageButtonProps {
  img: string
  onClick?: () => void
}

function ImageButton({img, onClick}: ImageButtonProps) {
  return (
    <div className={styles.imageButton} onClick={onClick}>
      <img src={img} className={styles.imageButtonImg} />
    </div>
  )
}

interface ReviewBoxProps {
  title: React.ReactNode | React.ReactNode[][]
  content: string
  lang: Lang
}

function ReviewBox({title, content, lang}: ReviewBoxProps) {
  return (
    <div className={styles.reviewBox}>
      <div className={styles.reviewBoxTop}>
        <MultilineText
          textStyle={TEXT_STYLE.reviewTitle}
          lang={lang}
          containerClassName={styles.reviewBoxTitle}
          text={title}
        />
        <span>
          <ReviewStar />
        </span>
      </div>

      <Text
        textStyle={TEXT_STYLE.reviewBody}
        lang={lang}
        className={styles.reviewBoxContent}>
        {content}
      </Text>
    </div>
  )
}

const DELAY = 500

const isBrowser = typeof window !== 'undefined'

export default function ReviewAndStoreTemplate({lang}: {lang: Lang}) {
  const t = useLocalizedInfo(lang, 'reviewAndStore')
  const gtag = isBrowser ? window.gtag : () => {}

  const triggerRef = useRef(null)

  const entry = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  })

  return (
    <section className={`review-and-store ${styles.container}`}>
      <div className={styles.content} ref={triggerRef}>
        <div className={styles.textWrap}>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY}>
            <MultilineText
              text={t.title}
              textStyle={TEXT_STYLE.title1}
              lang={lang}
              containerClassName={styles.title}
              textAlign="center"
            />
          </FadeFromBottom>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 300}>
            <MultilineText
              text={t.description}
              containerClassName={styles.description}
              textStyle={TEXT_STYLE.body1}
              lang={lang}
              textAlign="center"
            />
          </FadeFromBottom>
        </div>
        <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 600}>
          <div className={styles.buttonWrap}>
            <a
              href="https://apps.apple.com/app/sumone/id1469506430"
              target="_blank"
              rel="noreferrer noopener">
              <ImageButton
                img={appStore}
                onClick={
                  isBrowser
                    ? () => {
                        gtag('event', 'install_button_click', {
                          section: 'review-and-store',
                          store: 'apple',
                        })
                      }
                    : undefined
                }
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.sumone"
              target="_blank"
              rel="noreferrer noopener">
              <ImageButton
                img={googlePlay}
                onClick={
                  isBrowser
                    ? () => {
                        gtag('event', 'install_button_click', {
                          section: 'review-and-store',
                          store: 'google',
                        })
                      }
                    : undefined
                }
              />
            </a>
          </div>
        </FadeFromBottom>
        <Float duration={1500}>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 900}>
            <div className={styles.wrap}>
              <div className={styles.vertical}>
                <ReviewBox
                  title={t.review1.title}
                  content={t.review1.content}
                  lang={lang}
                />
                <ReviewBox
                  title={t.review2.title}
                  content={t.review2.content}
                  lang={lang}
                />
              </div>
              <div className={`${styles.vertical} ${styles.rightReview}`}>
                <ReviewBox
                  title={t.review3.title}
                  content={t.review3.content}
                  lang={lang}
                />
                <ReviewBox
                  title={t.review4.title}
                  content={t.review4.content}
                  lang={lang}
                />
              </div>
            </div>
          </FadeFromBottom>
        </Float>
      </div>
    </section>
  )
}
