import {EN} from './en'
import {JA} from './ja'
import {KO} from './ko'
import {TW} from './tw'
import {LANG, type Lang} from '@constants/theme'

export function getLocalizedInfo(lang: Lang) {
  switch (lang) {
    case LANG.ko:
      return KO
    case LANG.ja:
      return JA
    case LANG.tw:
      return TW
    case LANG.en:
      return EN
  }
}
