import React, {useRef} from 'react'
import * as styles from './QuoteTemplate.css'
import FadeFromBottom from '@components/atoms/FadeFromBottom'
import MultilineText, {Text, TEXT_STYLE} from '@components/atoms/Text'
import Typing from '@components/atoms/Typing'
import {Lang} from '@constants/theme'
import useIntersectionObserver from '@hooks/useIntersectionObserver'
import useLocalizedInfo from '@hooks/useLocalizedInfo'

const DELAY = 500

export default function QuoteTemplate({lang}: {lang: Lang}) {
  const t = useLocalizedInfo(lang, 'quote')

  const triggerRef = useRef(null)

  const entry = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  })

  return (
    <section className={`quote ${styles.container}`}>
      <div className={styles.content}>
        <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY}>
          <div className={styles.textArea} ref={triggerRef}>
            <span
              className={styles.quotationMark}
              style={lang === 'ko' ? {fontFamily: 'Noto Sans KR'} : undefined}>
              “
            </span>
            <Text textStyle={TEXT_STYLE.headline} lang={lang}>
              <Typing
                text={t.quoteText}
                containerClassName={styles.quoteText}
                isVisible={entry?.isIntersecting}
                delay={DELAY}
                textAlign="center"
              />
            </Text>
            <span
              className={`${styles.quotationMark} ${styles.quotationMarkRight}`}
              style={lang === 'ko' ? {fontFamily: 'Noto Sans KR'} : undefined}>
              ”
            </span>
          </div>
        </FadeFromBottom>
        <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 500}>
          <MultilineText
            text={t.description}
            textStyle={TEXT_STYLE.body2}
            lang={lang}
            textAlign="center"
            containerClassName={styles.description}
          />
        </FadeFromBottom>
      </div>
    </section>
  )
}
