import {useSpring, animated, easings} from '@react-spring/web'
import * as React from 'react'

interface Props {
  children?: React.ReactChild
  floatY?: number
  duration?: number
  id?: string
  className?: string
  delay?: number
}

export default function Float({
  children,
  floatY = 5,
  duration = 2000,
  delay = 0,
  id,
  className,
}: Props) {
  const [floatStyle, api] = useSpring(() => ({
    config: {duration, easing: easings.easeInOutQuad},
    from: {y: -floatY},
  }))

  React.useEffect(() => {
    setTimeout(() => {
      api({
        y: floatY,
        loop: {reverse: true},
      })
    }, delay)
  }, [])

  return (
    <animated.div style={floatStyle} id={id} className={className}>
      {children}
    </animated.div>
  )
}
