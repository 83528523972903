import type {RenderText} from './types'

export const EN = {
  title: {
    description: [['A special space to share ', 'as a couple']],
  },
  quote: {
    quoteText: [
      ['When did you ', 'start thinking'],
      ["'I am serious about ", "this person'?"],
    ],
    description: [
      ['As you gather sincere answers ', 'to sweet questions, '],
      ['They become precious memories ', 'that you can read again and again.'],
    ],
  },
  introduction: {
    title: (renderMainText: RenderText) => [
      ['Receive a new ', renderMainText('Question')],
      ['every day'],
    ],
    description: [
      [
        'Learn more about each other, day after day. ',
        'Sharing your deepest thoughts, ',
        'even if it feels awkward, ',
        'Is a sure way for you to get closer ',
        'and deepen your relationship.',
      ],
    ],
  },
  characters: {
    title: (renderMainText: RenderText) => [
      ['A character that you raise ', 'with your partner,'],
      [renderMainText('EggMon')],
    ],
    description: [
      [
        'Born from small eggs, ',
        'living on your home screen, ',
        'They grew feeding off of the love and cuteness ',
        'you share through your answers.',
      ],
    ],
  },
  themes: {
    title: (renderMainText: RenderText) => [
      [
        'Decorating ',
        renderMainText("EggMon's House "),
        'to match your tastes',
      ],
    ],
    description: [
      [
        "Freely decorate EggMon's room",
        'using the pebbles collected with your partner!',
      ],
    ],
  },
  reviewAndStore: {
    title: [['Check it out ', 'with your Significant Other!']],
    description: [['Used by 10 million couples around the world']],
    review1: {
      title: '130 Days Using App',
      content:
        "My boyfriend didn't want to use this at first, but now he loves it. The questions are a mix of cute and conversation-starting (today, it asked what we think our partner needs to fix in their life ASAP). I like this mix. Also, you use a free game currency to purchase things in Eggmon's room, and we debate together which pieces we want to save up for. It's nice to have a 'child' to take care of too haha. I really love this app and I can't wait to see what our Eggmon (named Alegg) turns into. I highly recommend this app!",
    },
    review2: {
      title: 'Very encouraging for long-distance!',
      content:
        "A very practical and fun app to strengthen the bond between two people in love. It's a very intuitive idea to have couples take care of a little pet together. For long-distance relationships this is a very encouraging and a lovely app. Thank you for making this app, developers!",
    },
    review3: {
      title: 'We love EggMons House',
      content:
        "Would definitely recommend to old and new couples! Me and my long distance partner started using it a month ago and we enjoy it so much! Answering cute and interesting questions every day, even if we've been arguing lately, just reminds us daily of how amazing the thing we have is. We also love decorating EggMons home together. Collecting pebbles and working on something together even when we can't see each other feels really special.",
    },
    review4: {
      title: 'Best Couple App! So cute',
      content:
        "I love this app it's the best couples app! Keeps my partner and I engaged with interesting questions everyday and I love how it keeps track of how long we've been together. I keep the widget for this app on my home screen and I love the customization options it makes us wanna collect all the pebbles. Overall the art is adorable as well!",
    },
  },
  footer: {
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
    contact: 'Business Inquiry',
    faq: 'FAQ',
  },
}
