import React, {useMemo} from 'react'
import * as styles from './Text.css'
import {LANG, Lang} from '@constants/theme'

export const TEXT_STYLE = styles.TEXT_STYLE

type TextStyle = styles.TextStyle

function getTextStyle(textStyle?: TextStyle, lang?: Lang) {
  if (textStyle === undefined || lang === undefined) {
    return undefined
  }

  const realLang = lang === LANG.en ? LANG.ko : lang

  switch (textStyle) {
    case TEXT_STYLE.headline:
      return styles.headline[realLang]
    case TEXT_STYLE.title1:
      return styles.title1[realLang]
    case TEXT_STYLE.title2:
      return styles.title2[realLang]
    case TEXT_STYLE.body1:
      return styles.body1[realLang]
    case TEXT_STYLE.body2:
      return styles.body2[realLang]
    case TEXT_STYLE.button1:
      return styles.button1[realLang]
    case TEXT_STYLE.reviewTitle:
      return styles.reviewTitle[realLang]
    case TEXT_STYLE.reviewBody:
      return styles.reviewBody[realLang]
  }
}

function isReactNodeArray(
  arg: React.ReactNode | Array<Array<React.ReactNode>>,
): arg is Array<Array<React.ReactNode>> {
  return Array.isArray(arg)
}

interface TextProps {
  children?: React.ReactNode
  textStyle?: TextStyle
  lang?: Lang
  className?: string
}

export function Text({children, textStyle, lang, className}: TextProps) {
  const textStyleClassName = useMemo(
    () => getTextStyle(textStyle, lang),
    [textStyle, lang],
  )

  return (
    <span className={`${textStyleClassName} ${className}`}>{children}</span>
  )
}

interface MultilineTextProps {
  text: React.ReactNode | Array<Array<string | React.ReactNode>>
  textStyle?: TextStyle
  lang?: Lang
  textAlign?: 'left' | 'center' | 'right'
  containerClassName?: string
}

export default function MultilineText({
  text,
  textStyle,
  lang,
  textAlign = 'left',
  containerClassName,
}: MultilineTextProps) {
  const textStyleClassName = useMemo(
    () => getTextStyle(textStyle, lang),
    [textStyle, lang],
  )

  if (isReactNodeArray(text)) {
    return (
      <div
        className={`${textStyleClassName} ${styles.container[textAlign]} ${containerClassName}`}>
        {text.map((line, lineIndex) => (
          <div className={styles.line[textAlign]} key={`line${lineIndex}`}>
            {line.map((str, strIndex) => (
              <span className={styles.str} key={`str${strIndex}`}>
                {str}
              </span>
            ))}
          </div>
        ))}
      </div>
    )
  } else {
    return (
      <Text className={containerClassName} textStyle={textStyle} lang={lang}>
        {text}
      </Text>
    )
  }
}
