import React, {useState, useEffect, useMemo} from 'react'
import * as styles from './Typing.css'

interface TypingProps {
  text: Array<Array<string>>
  isVisible?: boolean
  speed?: number
  delay?: number
  textAlign?: 'left' | 'center' | 'right'
  containerClassName?: string
}

export default function Typing({
  text,
  isVisible = false,
  speed = 50,
  delay = 0,
  textAlign = 'left',
  containerClassName,
}: TypingProps) {
  const flattenText = useMemo(
    () => text.map(line => line.map(str => [...str])).flat(2),
    [],
  )

  const newText = useMemo(() => {
    let i = 0

    return text.map(line =>
      line.map(str =>
        [...str].map(ch => {
          const chInfo = {
            char: ch,
            index: i,
          }

          i += 1

          return chInfo
        }),
      ),
    )
  }, [])

  const [opacityList, setOpacityList] = useState(flattenText.map(() => 0))

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        opacityList.forEach((opacity, index) => {
          setTimeout(() => {
            setOpacityList(opacityList.map((op, idx) => (idx <= index ? 1 : 0)))
          }, speed * index)
        })
      }, delay)
    }
  }, [isVisible])

  return (
    <div className={`${styles.container[textAlign]} ${containerClassName}`}>
      {newText.map((line, lineIndex) => (
        <div className={styles.line[textAlign]} key={`line${lineIndex}`}>
          {line.map((str, strIndex) => (
            <span className={styles.str} key={`str${strIndex}`}>
              {[...str].map(ch => (
                <span
                  key={`ch${ch.index}`}
                  style={{
                    opacity: opacityList[ch.index],
                  }}>
                  {ch.char}
                </span>
              ))}
            </span>
          ))}
        </div>
      ))}
    </div>
  )
}
