import React from 'react'
import * as styles from './PhoneMockup.css'

interface Props {
  children?: React.ReactChild | React.ReactChild[]
  className?: string
}

export default function PhoneMockup({children, className}: Props) {
  return <div className={`${styles.phoneMockup} ${className}`}>{children}</div>
}
