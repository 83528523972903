import React, {useRef, useCallback} from 'react'
import * as styles from './CharactersTemplate.css'
import charactersGif from '@assets/images/characters/section4gif.webp'
import FadeFromBottom from '@components/atoms/FadeFromBottom'
import MultilineText, {TEXT_STYLE} from '@components/atoms/Text'
import {Lang} from '@constants/theme'
import useIntersectionObserver from '@hooks/useIntersectionObserver'
import useLocalizedInfo from '@hooks/useLocalizedInfo'

const DELAY = 500

export default function CharactersTemplate({lang}: {lang: Lang}) {
  const t = useLocalizedInfo(lang, 'characters')

  const triggerRef = useRef(null)

  const entry = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true,
  })

  const renderMainText = useCallback(
    (text: string) => (
      <span className={styles.mainColor} key="title2">
        {text}
      </span>
    ),
    [],
  )

  return (
    <section className={`characters ${styles.container}`}>
      <div className={styles.content} ref={triggerRef}>
        <div className={styles.textWrap}>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY}>
            <MultilineText
              text={t.title(renderMainText)}
              textStyle={TEXT_STYLE.title2}
              lang={lang}
              containerClassName={styles.title}
            />
          </FadeFromBottom>
          <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 300}>
            <MultilineText
              text={t.description}
              textStyle={TEXT_STYLE.body2}
              lang={lang}
              containerClassName={styles.description}
            />
          </FadeFromBottom>
        </div>
        <FadeFromBottom isVisible={entry?.isIntersecting} delay={DELAY + 600}>
          <div className={styles.illustWrap}>
            <img src={charactersGif} width="100%" />
          </div>
        </FadeFromBottom>
      </div>
    </section>
  )
}
