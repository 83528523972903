import {useSpring, animated} from '@react-spring/web'
import * as React from 'react'

interface Props {
  children?: React.ReactNode
  y?: number
  frequency?: number
  isVisible?: boolean
  delay?: number
  containerClassName?: string
}

export default function FadeFromBottom({
  children,
  y = 50,
  frequency = 1,
  isVisible = false,
  delay = 0,
  containerClassName,
}: Props) {
  const [reallyVisible, setReallyVisible] = React.useState(isVisible)

  const fadeStyle = useSpring({
    config: {frequency},
    from: {opacity: 0, y},
    to: {
      opacity: reallyVisible ? 1 : 0,
      y: reallyVisible ? 0 : y,
    },
  })

  React.useEffect(() => {
    setTimeout(() => {
      setReallyVisible(isVisible)
    }, delay)
  }, [isVisible])

  return (
    <animated.div style={fadeStyle} className={containerClassName}>
      {children}
    </animated.div>
  )
}
