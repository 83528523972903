export const BREAKPOINTS = [450, 740, 1100] as const
export const MEDIA_QUERIES = {
  mobile: `screen and (max-width: ${BREAKPOINTS[0] - 1}px)`,
  tablet1: `screen and (min-width: ${BREAKPOINTS[0]}px) and (max-width: ${
    BREAKPOINTS[1] - 1
  }px)`,
  tablet2: `screen and (min-width: ${BREAKPOINTS[1]}px) and (max-width: ${
    BREAKPOINTS[2] - 1
  }px)`,
  pc1: `screen and (min-width: ${BREAKPOINTS[1]}px)`,
  pc2: `screen and (min-width: ${BREAKPOINTS[2]}px)`,
} as const

export const LANG = {
  ko: 'ko',
  ja: 'ja',
  tw: 'tw',
  en: 'en',
} as const
export type Lang = typeof LANG[keyof typeof LANG]

export const COLORS = {
  main700: '#f98f75',
  main500: '#ffae9a',
  main300: '#ffd2c7',
  mono900: '#000000',
  mono800: '#444444',
  mono700: '#676767',
  mono600: '#848484',
  mono500: '#a0a0a0',
  mono400: '#b0b0b0',
  mono300: '#e0e0e0',
  mono200: '#f0f0f0',
  mono150: '#f3f3f3',
  mono100: '#ffffff',
  button500: '#c5b698',
  buttonSpecial: '#c9b589',
  rustyRed: '#c11a1a',
} as const
