import {useMemo} from 'react'
import {getLocalizedInfo} from '@constants/locales/utils'
import type {Lang} from '@constants/theme'

export default function useLocalizedInfo<
  T extends keyof ReturnType<typeof getLocalizedInfo>,
>(lang: Lang, category: T): ReturnType<typeof getLocalizedInfo>[T] {
  const t = useMemo(() => getLocalizedInfo(lang)[category], [lang, category])

  return t
}
