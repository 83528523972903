import "sumone-landing/src/components/templates/ReviewAndStoreTemplate.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"source\":\"#H4sIAAAAAAAAA81V246bMBB936+wtKq0kQoyVxP60r70NyoHO+AWbGScDVGVf68NdgSENJvVVipEJJk5Hs/MOWP8H0FVxX2FIfj9BMCREVXlIIDw0xf9d4eLX6UUB068QtRC5uD5+zdzGydhXVvjUw72Ne2N4eehU2x/0lCuKFc5KPSTSuPCNSu5xxRtuqm5xYQwXnpKtMOmbT+17oRSotEOlBjH+cl3yQZDslcJmG+PMEkLxQTXG4n60PD3pDbZK/zXewFQYl1+jGbV5wCCKFrUHQ25OCri4ZoB4nuA5G3VSHG8JBZYWhosS8ZHrtLtIrN0Kp8w2I5LKsrKSndgJPBDJLMiyf1wDU4hCZWexIQd9CqbxWjVhbQ96ETNCHim0NzGqSTmHRurFi0umDoB6CfdvLq8Eq9UDjVakObHz/4eQBNxkJ1JsRXsSldpjnWzX+mHBkVDNNf2KPa38YKo7CEBTDnPbDtHtcJF3O2jYzKGCRdh8DwM4xWVTM0gu6nU4hDekNadnYNsMW6h1igI9cSBCJpfVrKP6C0IneB6r6swEUczxdoIzEAAWe7wC/wM7MeH8WZWWPGuFmaLDpJ7RwCdAVJkbgP42lDCMOgKSSkHmBPw0uDec42OdQmbYak/f2csjvHEEnJ9jie25edpjNDGGGpxZM4QiUVMtZiEK8DUAt1LDCErf2N0M5HCi3G2GNnFDhdmfrRdwWUr2cTBChBb4IVPLji9Qu3mOUcBcgmvn2VLcZkzLYhvictstk6sTt0RawjbXPGNojfyjW7yHbgZuk14tIb4/wlH6DHCz38A5yT/XWcJAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var buttonWrap = '_1hh4xha5';
export var container = '_1hh4xha0';
export var content = '_1hh4xha1';
export var description = '_1hh4xha4';
export var imageButton = '_1hh4xha6';
export var imageButtonImg = '_1hh4xha7';
export var reviewBox = '_1hh4xhab';
export var reviewBoxContent = '_1hh4xhae';
export var reviewBoxTitle = '_1hh4xhad';
export var reviewBoxTop = '_1hh4xhac';
export var rightReview = '_1hh4xhaa';
export var textWrap = '_1hh4xha2';
export var title = '_1hh4xha3';
export var vertical = '_1hh4xha9';
export var wrap = '_1hh4xha8';